var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "report-table-panel" },
    [
      _c("BlockTitle", {
        staticClass: "report-panel-title",
        attrs: { title: _vm.title },
      }),
      _c(
        "div",
        { staticClass: "report-panel-tb" },
        [
          _c("customTable", {
            attrs: {
              columns: _vm.columns,
              "data-source": _vm.tableList,
              "row-key": "reportId",
              pagination: false,
              loading: _vm.loading,
            },
            on: { change: _vm.loadDataList },
            scopedSlots: _vm._u([
              {
                key: "operation",
                fn: function (ref) {
                  var scope = ref.scope
                  return [
                    _c(
                      "div",
                      { staticClass: "flex-align-center" },
                      [
                        [
                          _c(
                            "div",
                            { staticClass: "more-handle-btn-box" },
                            [
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "link" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.download(
                                        scope.record,
                                        _vm.title
                                      )
                                    },
                                  },
                                },
                                [_vm._v("下载")]
                              ),
                              _c(
                                "a-button",
                                {
                                  attrs: { type: "link" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.preview(
                                        scope.record,
                                        _vm.title
                                      )
                                    },
                                  },
                                },
                                [_vm._v("预览")]
                              ),
                            ],
                            1
                          ),
                        ],
                      ],
                      2
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c("previewTable", { ref: "previewTable" }),
      _vm.type === "attendance"
        ? _c("attendanceModel", {
            ref: "attendanceModel",
            on: {
              download: _vm.downloadAttenance,
              preview: _vm.previewAttendance,
            },
          })
        : _vm._e(),
      _vm.type === "insurance"
        ? _c("InsuranceModel", {
            ref: "insuranceModel",
            on: {
              download: _vm.downloadAttenance,
              preview: _vm.previewAttendanceInsurance,
            },
          })
        : _vm._e(),
      _vm.type === "payroll"
        ? _c("payrollModel", {
            ref: "payrollModel",
            on: {
              download: _vm.downloadAttenance,
              preview: _vm.previewAttendance,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }