import {
  ORGAN_REPORT
} from '../api'
import {
  METHOD,
  request
} from '@/utils/request'

// 报表页面数据查询
export async function getReportColumn(params) {
  return request(`${ORGAN_REPORT + '/getReportColumn'}`, METHOD.GET, params)
}

// 报表导出
export async function getReportExport(params) {
  return request(`${ORGAN_REPORT + '/export'}`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: true
    }
  })
}

// 报表预览
export async function getReportPreview(params) {
  return request(`${ORGAN_REPORT + '/preview'}`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: false
    }
  })
}
// 报表预览tab页
export async function getReportPreviewTab(params) {
  return request(`${ORGAN_REPORT + '/getPreviewTabList'}`, METHOD.GET, params)
}
// 报表预览
export async function getPreviewByTabId(params) {
  return request(`${ORGAN_REPORT + '/previewByTabId'}`, METHOD.GET, params, {
    responseType: 'blob',
    headers: {
      isDownload: false
    }
  })
}
export default {
  getReportColumn,
  getReportExport,
  getReportPreview
}
