<template>
  <div class="report-table-panel">
    <BlockTitle :title="title" class="report-panel-title" />
    <div class="report-panel-tb">
      <customTable
        :columns="columns"
        :data-source="tableList"
        :row-key="'reportId'"
        :pagination="false"
        :loading="loading"
        @change="loadDataList"
      >
        <template slot="operation" slot-scope="{ scope}">
          <div class="flex-align-center">
            <template>
              <div class="more-handle-btn-box">
                <a-button type="link" @click="download(scope.record, title)">下载</a-button>
                <a-button type="link" @click="preview(scope.record, title)">预览</a-button>
              </div>
            </template>
            <!-- <a-popover
              placement="bottom"
              trigger="hover"
            >
              <template slot="content">
                <div id="more-handle-btn">
                  <a-button type="link" @click="download(scope.record, title)">下载</a-button>
                  <a-button type="link" @click="preview(scope.record, title)">预览</a-button>
                </div>
              </template>
              <div class="more-handle">
                ···
              </div>
            </a-popover> -->
          </div>
        </template>
      </customTable>
    </div>
    <previewTable ref="previewTable" />
    <attendanceModel v-if="type==='attendance'" ref="attendanceModel" @download="downloadAttenance" @preview="previewAttendance" />
    <!-- <payrollModel v-if="type==='payroll'" ref="payrollModel" /> -->
    <InsuranceModel v-if="type==='insurance'" ref="insuranceModel" @download="downloadAttenance" @preview="previewAttendanceInsurance" />
    <payrollModel v-if="type==='payroll'" ref="payrollModel" @download="downloadAttenance" @preview="previewAttendance" />
    <!-- <InsuranceModel v-if="type==='insurance'" ref="insuranceModel" @download="downloadAttenance" @preview="previewAttendance" /> -->
  </div>
</template>

<script>
import mixins from '@/components/CustomTable/index.js'
import { getReportExport, getReportPreview, getReportPreviewTab } from '@/services/organization/reportForm.js'
import { csv } from '@/utils/file-reader.js'
export default {
  name: 'ReportTablePanel',
  components: {
    BlockTitle: () => import ('@/components/CbBlockTitle/index'),
    previewTable: () => import('./previewTable.vue'),
    payrollModel: () => import('./payrollModel.vue'),
    attendanceModel: () => import('./attendanceModel.vue'),
    InsuranceModel: () => import('./insuranceModel')
  },
  mixins: [mixins],
  props: {
    title: {
      type: String,
      default: ''
    },
    tableList: {
      type: Array,
      default: () => {
        return []
      }
    },
    type: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      columns: [
        {
          title: '报表名称',
          dataIndex: 'reportName',
          key: 'reportName',
          width: '320px',
          ellipsis: true
        },
        {
          title: '描述',
          dataIndex: 'detail',
          key: 'detail',
          ellipsis: true
        },
        {
          title: '操作',
          dataIndex: 'operation',
          scopedSlots: { customRender: 'operation' },
          width: '170px'
        }
      ],
      csvData: [],
      selectedRowKeys: [], // 当前选择列集合
      dataSource: [], // 数据
      // queryForm: this.$form.createForm(this, { name: 'horizontal_login' }), // 查询的form表单
      lazyInitData: true,
      tenantReportId: '',
      record: null
    }
  },
  methods: {
    // 下载
    download(record, title) {
      this.tenantReportId = record.reportId
      if (this.type === 'attendance') {
        this.$refs.attendanceModel.show('download')
      } else if (this.type === 'payroll') {
        this.$refs.payrollModel.show('download', title)
      } else if (this.type === 'insurance') {
        this.$refs.insuranceModel.show('download')
      } else {
        getReportExport({ tenantReportId: record.reportId })
      }
    },
    downloadAttenance(data) {
      getReportExport({ tenantReportId: this.tenantReportId, ...data })
    },
    previewAttendance(params) {
      getReportPreview({ tenantReportId: this.tenantReportId, ...params }).then((res) => {
        const blob = new Blob([res], { type: 'text/csv' })
        csv(blob).then((data) => {
          const csvData = data
          if (csvData[csvData.length - 1].every((v) => v === '')) {
            // 去除最后的空行
            csvData.pop()
          }
          this.$refs.previewTable.preview({
            ...this.record,
            csvData: csvData
          }, params)
        })
      })
    },
    // 社保预览
    previewAttendanceInsurance(params) {
      this.record.insureMonth = params.insureMonth
      this.record.insureCompanyIds = params.insureCompanyIds
      if (this.record.multiple === 1) {
        getReportPreviewTab({ tenantReportId: this.record.reportId, ...params }).then((res) => {
          const csvData = res.data
          this.$refs.previewTable.preview({
            ...this.record,
            csvData: csvData
          })
        })
      } else {
        getReportPreview({ tenantReportId: this.record.reportId, ...params }).then((res) => {
          const blob = new Blob([res], { type: 'text/csv' })
          csv(blob).then((data) => {
            const csvData = data
            if (csvData[csvData.length - 1].every((v) => v === '')) {
              // 去除最后的空行
              csvData.pop()
            }
            this.$refs.previewTable.preview({
              ...this.record,
              csvData: csvData
            })
          })
        })
      }
    },
    // 预览
    preview(record, title) {
      this.record = record
      this.tenantReportId = record.reportId
      // 多选
      if (this.type === 'attendance') {
        this.$refs.attendanceModel.show('preview')
      } else if (this.type === 'payroll') {
        this.$refs.payrollModel.show('preview', title)
      } else if (this.type === 'insurance') {
        this.$refs.insuranceModel.show('preview')
      } else {
        if (record.multiple === 1) {
          getReportPreviewTab({ tenantReportId: record.reportId }).then((res) => {
            const csvData = res.data
            console.log('csvData', csvData)
            this.$refs.previewTable.preview({
              ...record,
              csvData: csvData
            })
          })
        } else {
          getReportPreview({ tenantReportId: record.reportId }).then((res) => {
            const blob = new Blob([res], { type: 'text/csv' })
            csv(blob).then((data) => {
              const csvData = data
              if (csvData[csvData.length - 1].every((v) => v === '')) {
              // 去除最后的空行
                csvData.pop()
              }
              this.$refs.previewTable.preview({
                ...record,
                csvData: csvData
              })
            })
          })
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.report-table-panel {
  .report-panel-title {
    margin-bottom: 20px;
  }
  .report-panel-tb {
    padding-left: 30px;
    /deep/.ant-table-header-column{
      margin-left: 20px;
    }
  }
  .more-handle {
    margin-left: 25px;
    font-weight: 700;
    vertical-align: baseline;
    cursor: pointer;
    color: #1890ff;
  }
}

   #more-handle-btn{
    display: flex ;
    flex-direction: column ;
  }
  .more-handle-btn-box{
    button{
      padding-left: 0;
      padding-right: 16px;
    }
  }

</style>
